declare module 'o365.pwa.declaration.shared.dexie.objectStores.GlobalSetting.d.ts' {
    export class GlobalSetting {
        static objectStoreDexieSchema: string;

        id: number;
        cdnUrl: string;

        constructor(cdnUrl: string);

        save(): Promise<void>;
        delete(): Promise<void>;
        forceReload(): Promise<GlobalSetting | null>;
    }
}

export {}
